<template>
	<div class="main">
		<el-card class="card_box">
			<div
				slot="header"
				class="card_title"
				@click="$router.push({ path: '/user/adduser' })"
			>
				<i class="el-icon-arrow-left"></i>
				<span>用户管理/用户列表/详情</span>
			</div>
			<div class="add_main">
				<span class="font_weight_bold">账号信息</span>
				<div class="margin_top_20">
					<el-form
						label-position="right"
						label-width="120px"
						:model="formData"
						:rules="rule"
						ref="formData"
					>
						<el-form-item label="头像" prop="avatar_img">
							<img
								style="width: 80%"
								v-if="formData.avatar_img"
								:src="formData.avatar_img"
								@click="uploadPicture('0')"
							/>
							<div v-else>
								<div class="el-upload__text" @click="uploadPicture(0)">
									<img
										style="width: 100px"
										src="../../../assets/images/uploadImg.png"
										alt=""
									/>
									<br /><em>点击加号上传</em></div
								>
							</div>
							<!-- 剪裁组件弹窗 -->
							<el-dialog
								title="裁切图片"
								:visible.sync="cropperModel"
								width="1300px"
								center
								:append-to-body="true"
							>
								<cropper-image
									:Name="cropperName"
									@uploadImgSuccess="handleUploadSuccess"
									ref="child"
								>
								</cropper-image>
							</el-dialog>
						</el-form-item>
						<el-form-item label="账号名称" prop="nickname">
							<el-input
								v-model="formData.nickname"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>

						<el-form-item label="用户密码" prop="passwordNew">
							<el-input v-model="formData.passwordNew"> </el-input>
						</el-form-item>
						<el-form-item label="性别" prop="sex">
							<el-select
								v-model="formData.sex"
								placeholder="请选择性别"
								:disabled="$route.query.type == 'detail'"
							>
								<el-option label="男" value="1"> </el-option>
								<el-option label="女" value="2"> </el-option>
								<el-option label="保密" value="3"> </el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="职位" prop="position">
							<el-input
								v-model="formData.position"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="介绍" prop="desc">
							<el-input
								v-model="formData.desc"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="微信" prop="wechat">
							<el-input
								v-model="formData.wechat"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="邮箱" prop="email">
							<el-input
								v-model="formData.email"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>

						<el-form-item label="twitter" prop="twitter">
							<el-input
								v-model="formData.twitter"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="账号状态" prop="state">
							<el-select
								v-model="formData.state"
								placeholder="请选择账号状态"
								:disabled="$route.query.type == 'detail'"
							>
								<el-option label="正常" value="1"> </el-option>
								<el-option label="禁用" value="2"> </el-option>
							</el-select>
						</el-form-item>
						<div class="margin_top_40 font_weight_bold">认证信息</div>
						<el-form-item
							label="姓名"
							class="margin_top_20"
							prop="userinfo.truename"
						>
							<el-input
								v-model="formData.userinfo.truename"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="身份证号">
							<el-input
								v-model="formData.userinfo.id_card"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="手机号码" prop="mobile">
							<el-input
								v-model="formData.mobile"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<div class="margin_top_40 font_weight_bold">请上传身份证正反面</div>
						<el-form-item class="margin_top_20">
							<div>
								<img
									style="width: 80%"
									v-if="formData.userinfo.id_card_img1"
									:src="formData.userinfo.id_card_img1"
									@click="uploadPicture(1)"
								/>
								<div v-else>
									<div class="el-upload__text" @click="uploadPicture(1)">
										<img
											style="width: 100px"
											src="../../../assets/images/uploadImg.png"
											alt=""
										/>
										<br /><em>点击加号上传身份证正面</em></div
									>
								</div>
								<img
									style="width: 80%"
									v-if="formData.userinfo.id_card_img2"
									:src="formData.userinfo.id_card_img2"
									@click="uploadPicture(2)"
								/>
								<div v-else>
									<div class="el-upload__text" @click="uploadPicture(2)">
										<img
											style="width: 100px"
											src="../../../assets/images/uploadImg.png"
											alt=""
										/>
										<br /><em>点击加号上传身份证反面</em></div
									>
								</div>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="submit('formData')"
									v-if="$route.query.type != 'detail'"
									>保存</el-button
								>
								<el-button class="custom_btn_width_100" @click="$router.back()"
									>返回</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import {
	validateNull,
	identityCard,
	validateEMail,
	validatePhone,
} from "@/utils/validate";
import {
	uploadImage,
	getUsersdetails,
	createusers,
	updateusers,
} from "@/api/user";
export default {
	name: "",
	data() {
		return {
			rule: {
				nickname: [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				sex: [{ validator: validateNull, trigger: "change", required: true }],
				email: [
					{ validator: validateNull, trigger: "blur", required: true },
					{ validator: validateEMail, trigger: "blur" },
				],
				mobile: [
					{ validator: validateNull, trigger: "blur", required: true },
					{ validator: validatePhone, trigger: "blur" },
				],
			},
			value: "",
			formData: {
				state: "",
				nickname: "",
				mobile: "",
				avatar_img: "",
				sex: "",
				desc: "",
				position: "",
				email: "",
				wechat: "",
				twitter: "",
				document_type: "",
				project_type: "",
				userinfo: {
					auth_type: "1",
					company_name: "",
					company_desc: "",
					company_no: "",
					company_img1: "",
					company_img2: "",
					project_logo: "",
					truename: "",
					id_card: "",
					id_card_img1: "",
					id_card_img2: "",
					project_name: "",
					project_contacts: "",
					project_phone: "",
					project_url: "",
					social_media: "",
					audit_report: "",
					github_url: "",
				},
			},
			//裁切图片参数
			cropperModel: false,
			cropperName: "",
		};
	},
	created() {
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.getUsersdetails();
		}
	},
	methods: {
		// 获取用户详情
		getUsersdetails() {
			const that = this;
			getUsersdetails({ d: that.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						that.formData = res.data;
						that.formData.sex = that.formData.sex.toString();
						that.formData.state = that.formData.state.toString();
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		// 新增
		submit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					const that = this;
					let form = {};
					if (
						this.formData.passwordNew != null &&
						this.formData.passwordNew.length > 0
					) {
						this.formData.password = this.formData.passwordNew;
					} else {
						delete this.formData.password;
					}
					this.formData.userinfo.auth_type = "0";
					for (let key in this.formData) {
						if (key === "userinfo") {
							for (let key2 in this.formData.userinfo) {
								form[key2] = this.formData.userinfo[key2];
							}
						} else {
							form[key] = this.formData[key];
						}
					}
					if (this.$route.query.type == "edit") {
						updateusers(form)
							.then((res) => {
								if (res.code == 200) {
									that.$router.push({ path: "/user/userlist" });
								} else {
								}
							})
							.catch((error) => {});
					} else {
						createusers(form)
							.then((res) => {
								if (res.code == 200) {
									that.$router.push({ path: "/user/userlist" });
								} else {
								}
							})
							.catch((error) => {});
					}
					// }
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		//封面设置
		uploadPicture(name) {
			this.cropperName = name;
			this.cropperModel = true;
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			if (this.cropperName == "0") {
				this.formData.avatar_img = data.url;
			} else if (this.cropperName == "1") {
				this.formData.userinfo.id_card_img1 = data.url;
			} else if (this.cropperName == "2") {
				this.formData.userinfo.id_card_img2 = data.url;
			} else {
			}
		},
		clearFormAdd() {
			for (let key in this.formData) {
				if (key === "userinfo") {
					for (let key2 in this.formData.userinfo) {
						this.formData.userinfo[key2] = "";
					}
				} else {
					this.formData[key] = "";
				}
			}
			console.log(this.formData);
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	margin: 0 auto;
}
</style>
